import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getMe } from '../features/authSlice';
import Header from "../component/Head/Header"
// import Features from "../component/Features/Features"
import Home from "../component/Hero/Home"
// import Portfolio from "../component/Portfolio/Portfolio"
// import Resume from "../component/Resume/Resume"
import Testimonial from "../component/Testimonial/Testimonial"
// import Blog from "../component/Blog/Blog"
// import Contact from "../component/Contact/Contact"
// import Footer from "../component/Footer"
import "./Hero.css"

const Hero = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);


    return (
        <>
            <Header />
            <Home />
            <Testimonial />
            {/* <Features /> */}
            {/* <Portfolio /> */}
            {/* <Resume /> */}
            {/* <Blog /> */}
            {/* <Contact /> */}
            {/* <Footer /> */}
        </>
    );
};

export default Hero;