import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import Products from "./pages/Products";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import Dpt from "./pages/Dpt";
import Login from "./components/Login";
import Hero from "./pages/Hero";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dpts" element={<Dpt />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/Users/add" element={<AddUser />} />
          <Route path="/Users/edit/:id" element={<EditUser />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Products/add" element={<AddProduct />} />
          <Route path="/Products/edit/:id" element={<EditProduct />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

