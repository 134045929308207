import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Card from 'react-bootstrap/Card';

const ProductList = () => {
    const [Products, setProducts] = useState([]);
    const { user } = useSelector((state) => state.auth);


    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = async () => {
        const response = await axios.get('http://localhost:5000/products');
        setProducts(response.data);
    };

    const deleteProduct = async (productId) => {
        const confirmDelete = window.confirm('Apakah Anda yakin ingin menghapus Data  ini?');
        if (confirmDelete) {
            await axios.delete(`http://localhost:5000/products/${productId}`);
            getProducts();
        }
    };

    return (
        <div>

            <Card border="info">
                <Card.Header className='level-right'>
                    <div class="level-item">
                        <p class="">
                            <strong><h3>Daftar Nama-Nama Tim Relawan</h3></strong>
                        </p>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Title className=''>  <h1 className='title has-text-centered'>DAFTAR NAMA ANGGOTA TIM RELAWAN  </h1></Card.Title>
                    <Card.Text className=''>
                        <Link to="/products/add" className='button is-primary mb-2'> Add New</Link>
                        <table className='table is-striped is-fullwidth'>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>NAMA LENGKAP</th>
                                    <th>ALAMAT</th>
                                    <th>JABATAN</th>
                                    <th>Created By</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Products.map((product, index) => (
                                    <tr key={product.uuid}>
                                        <td><div> <p>{index + 1}</p></div></td>
                                        <td><div>
                                            <p>{product.dpt.name} </p>
                                        </div></td>
                                        <td><div>
                                            <p>{product.alamat} </p>
                                        </div></td>

                                        <td> <div><p>{product.jabatan}</p>
                                        </div></td>

                                        <td><div><p>{product.user.name}</p>
                                        </div></td>
                                        <td>
                                            <Link to={`/products/edit/${product.uuid}`} className='button is-small is-info'>Edit</Link>
                                            <Link to={`/products/edit/${product.uuid}`} className='button is-small is-primary'>Detail</Link>
                                            <button onClick={() => deleteProduct(product.uuid)} className='button is-small is-danger'>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </Card.Text>
                </Card.Body>
            </Card>



        </div>
    );
};

export default ProductList;