const sidebarNav = [
    {
        link: '/dashboard',
        section: 'Dashboard',
        icon: <i className='bx bx-home-alt'></i>,
        text: 'Home'
    },
    {
        link: '/dpts',
        section: 'DPT',
        icon: <i className='bx bx-receipt' ></i>,
        text: 'Data Pemilih Tetap'
    },
    {
        link: '/Products',
        section: 'Team',
        icon: <i className='bx bx-cube'></i>,
        text: 'Tem Relawan'
    },
    {
        link: '#',
        section: 'User',
        icon: <i className='bx bx-user'></i>,
        text: 'Users'
    },
    {
        link: '#',
        section: 'stats',
        icon: <i className='bx bx-line-chart'></i>,
        text: 'Stats'
    },
    {
        link: '#',
        section: 'settings',
        icon: <i className='bx bx-cog'></i>,
        text: 'Settings'
    },
    {
        link: '#',
        section: 'Admin',
        icon: <i className='bx bx-shield'></i>,
        text: 'Admin'
    }
];

export default sidebarNav;
