import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';


const FormAddProduct = () => {
    const [name, setName] = useState("");
    const [jabatan, setJabatan] = useState("");
    const [alamat, setAlamat] = useState("");
    const [hp, setHp] = useState("");
    const [image, setImage] = useState("");
    const [dptId, setDptId] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const saveProduct = async (e) => {
        e.preventDefault();
        try {
            if (!dptId) {
                setMsg("DptId harus diisi.");
            } else {
                await axios.post('http://localhost:5000/products', {
                    name,
                    jabatan,
                    alamat,
                    hp,
                    image,
                    dptId,
                });
                setMsg("Data berhasil ditambahkan.");
                // Reset state untuk persiapan input data baru
                setName("");
                setJabatan("");
                setAlamat("");
                setHp("");
                setImage("");
                setDptId("");
                navigate("/products");
            }
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <Card border="info" style={{ width: '' }}>
                <Card.Header>
                    <h1 className='title has-text-centered'>Form Tambah Data Team Relawan</h1>
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        <h2 className='subtitle has-text-centered'>Mohon untuk Memperhatikan Id DPT saat menambahkan Anggota baru</h2>
                    </Card.Title>
                    <Card.Text>
                        <div className="card is-shadowless">
                            <div className="card-content">
                                <div className="content">
                                    <form onSubmit={saveProduct}>
                                        <p className='has-text-centered'>{msg}</p>
                                        <div className="field">
                                            <label className='label'>Dpt ID</label>
                                            <div className="control">
                                                <input
                                                    type="text"
                                                    value={dptId}
                                                    onChange={(e) => setDptId(e.target.value)}
                                                    className="input"
                                                    placeholder='Dpt ID'
                                                />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>Name Lengkap</label>
                                            <div className="control">
                                                <input
                                                    type="text"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    className="input"
                                                    placeholder='Name'
                                                />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>Alamat</label>
                                            <div className="control">
                                                <input
                                                    type="text"
                                                    className="input"
                                                    value={alamat}
                                                    onChange={(e) => setAlamat(e.target.value)}
                                                    placeholder='Alamat'
                                                />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>No. Telepon</label>
                                            <div className="control">
                                                <input type="text" className="input" value={hp}
                                                    onChange={(e) => setHp(e.target.value)} placeholder='+6208...' />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>Jabatan</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={jabatan}
                                                        onChange={(e) => setJabatan(e.target.value)}>
                                                        <option value="#">Pilih Jabatan</option>
                                                        <option value="KETUA KORDINATOR">Ketua Kordinator</option>
                                                        <option value="KORDINATOR">Kordinator</option>
                                                        <option value="SEKRETARIS">Sekretaris</option>
                                                        <option value="BENDAHARA">Bendahara</option>
                                                        <option value="ANGGOTA">Anggota</option>
                                                        <option value="SAKSI">Saksi</option>
                                                        <option value="SAKSI BAYANGAN">Saksi</option>
                                                        <option value="SAKSI PARTAI">Saksi Partai</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>image</label>
                                            <div className="control">
                                                <input type="text" className="input" value={image}
                                                    onChange={(e) => setImage(e.target.value)} placeholder='Keterangan' />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="control">
                                                <button type="submit" className="button is-success">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};

export default FormAddProduct;
