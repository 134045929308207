import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Card from 'react-bootstrap/Card';

const DptList = () => {
    const [dpts, setDpt] = useState([]);
    const [page, setPage] = useState(0);
    const [limit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        getDpt();
    }, [page, keyword]);

    const getDpt = async () => {
        const response = await axios.get(
            `http://localhost:5000/dpts?search_query=${keyword}&page=${page}&limit=${limit}`
        );
        setDpt(response.data.result);
        setPage(response.data.page);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
    };

    const changePage = ({ selected }) => {
        setPage(selected);
        if (selected === 9) {
            setMsg(
                'Jika tidak menemukan Data yang Anda cari, silakan cari Data dengan kata kunci spesifik!'
            );
        } else {
            setMsg('');
        }
    };

    const searchData = (e) => {
        e.preventDefault();
        setPage(0);
        setKeyword(query);
    };

    return (
        <div className="mt-6">
            <Card border="info" style={{ width: 'auto' }}>
                <Card.Header className="level-right">
                    <div className="level-item">
                        <p className="">
                            <strong>MODEL A-KabKo Daftar Pemilih</strong>
                        </p>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        <h1 className="title has-text-centered">DAFTAR PEMILIH TETAP</h1>
                        <h3 className="title has-text-centered">
                            PEMILIHAN UMUM TAHUN 2024
                        </h3>
                    </Card.Title>
                    <Card.Text>
                        <div className="">
                            <div className="column is-centered">
                                <form onSubmit={searchData}>
                                    <div className="level">
                                        <div className="level-right">
                                            <p className="level-item">
                                                <strong>Total Data : {rows} </strong>
                                            </p>
                                        </div>
                                        <div className="level-left">
                                            <div className="level-item">
                                                <p className="subtitle is-5">
                                                    <strong> </strong>
                                                </p>
                                            </div>
                                            <div className="level-item">
                                                <div className="field has-addons">
                                                    <p className="control">
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            value={query}
                                                            onChange={(e) => setQuery(e.target.value)}
                                                            placeholder="Ketik Nama"
                                                        />
                                                    </p>
                                                    <p className="control">
                                                        <button
                                                            type="submit"
                                                            className="button is-info"
                                                        >
                                                            Cari
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="columns is-multiline">
                                    {dpts.map((dpt) => (
                                        <div key={dpt.id} className="column is-one-third">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>{dpt.name}</Card.Title>

                                                    <Card.Text>
                                                        <p>Nama: {dpt.name}</p>
                                                        <p>Umur: {dpt.umur}</p>
                                                        <p>L/P: {dpt.lp}</p>
                                                        <p>Distrik: {dpt.distrik}</p>
                                                        <p>Kampung: {dpt.kampung}</p>
                                                        <p>Tps: {dpt.tps}</p>
                                                        <p>Dapil: {dpt.dapil}</p>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                                <p>
                                    Halaman : {rows ? page + 1 : 0} Dari : {pages}
                                </p>
                                <p className="has-text-centered has-text-danger">{msg}</p>
                                <nav
                                    className="pagination is-centered mt-5"
                                    key={rows}
                                    role="navigation"
                                    aria-label="pagination"
                                >
                                    <ReactPaginate
                                        previousLabel="<"
                                        nextLabel=" >"
                                        pageCount={Math.min(10, pages)}
                                        onPageChange={changePage}
                                        containerClassName="pagination-list"
                                        pageLinkClassName="pagination-link"
                                        previousLinkClassName="pagination-previous"
                                        nextLinkClassName="pagination-next"
                                        activeLinkClassName="pagination-link is-current"
                                        disabledLinkClassName="pagination-link is-disabled"
                                    />
                                </nav>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};

export default DptList;
