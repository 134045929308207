import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Card from 'react-bootstrap/Card';

const FormEditProduct = () => {
    const [name, setName] = useState("");
    const [lp, setLp] = useState("");
    const [jabatan, setJabatan] = useState("");
    const [alamat, setAlamat] = useState("");
    const [hp, setHp] = useState("");
    const [image, setImage] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const getProductById = async () => {
            try {
                const response = await axios.get(`http://localhost:5000/products/${id}`);
                setName(response.data.name);
                setLp(response.data.lp);
                setJabatan(response.data.jabatan);
                setAlamat(response.data.alamat);
                setHp(response.data.hp);
                setImage(response.data.image);
            } catch (error) {
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        }
        getProductById();
    }, [id]);

    const updateProduct = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(`http://localhost:5000/products/${id}`, {
                name: name,
                lp: lp,
                jabatan: jabatan,
                alamat: alamat,
                hp: hp,
                image: image
            });
            navigate("/products");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    return (
        <div>
            <Card border="info" style={{ width: 'auto' }}>
                <Card.Header>  <h1 className='title has-text-centered'>From Edit Data Team Relawan</h1></Card.Header>
                <Card.Body>
                    <Card.Title> <h2 className='subtitle has-text-centered'>EDIT DATA ANGGOTA SESUAI KTP </h2></Card.Title>
                    <Card.Text>
                        <div className="card is-shadowless">
                            <div className="card-content">
                                <div className="content">
                                    <form onSubmit={updateProduct}>
                                        <p className='has-text-centered'>{msg}</p>
                                        <div className="field">
                                            <label className='label'>Name Lengkap</label>
                                            <div className="control">
                                                <input type="text" value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    className="input" readOnly placeholder='Name' />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>Alamat</label>
                                            <div className="control">
                                                <input type="text" className="input" value={alamat}
                                                    onChange={(e) => setAlamat(e.target.value)} placeholder='Alamat' />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>No. Telepon</label>
                                            <div className="control">
                                                <input type="text" className="input" value={hp}
                                                    onChange={(e) => setHp(e.target.value)} placeholder='+6208...' />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>Jenis Kelamin</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={lp} readOnly
                                                        onChange={(e) => setLp(e.target.value)}>
                                                        <option value="#">Pilih Jenis Lelamin</option>
                                                        <option value="L">Laki-Laki</option>
                                                        <option value="P">Perempuan</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>Jabatan</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={jabatan}
                                                        onChange={(e) => setJabatan(e.target.value)}>
                                                        <option value="#">Pilih Jabtan</option>
                                                        <option value="ketua kordinator">Ketua Kordinator</option>
                                                        <option value="kordinator">Kordinator</option>
                                                        <option value="sekretaris">Sekretaris</option>
                                                        <option value="bendahara">Bendahara</option>
                                                        <option value="anggota">Anggota</option>
                                                        <option value="ketua saksi">Ketua Saksi</option>
                                                        <option value="saksi">Saksi </option>
                                                        <option value="saksi partai">Saksi Partai</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className='label'>Foto</label>
                                            <div className="control">
                                                <input type="text" className="input" value={image}
                                                    onChange={(e) => setImage(e.target.value)} placeholder='Keterangan' />
                                            </div>
                                        </div>


                                        <div className="field">
                                            <div className="control">
                                                <button type="submit" className="button is-success">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>



        </div>

    );
};

export default FormEditProduct;